<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-container topBottom>
      <f-form />
    </f-container>
  </div>
</template>

<script>
import FForm from '../../components/LearningPaths/Form.vue';

export default {
  components: {
    FForm,
  },
  computed: {
    title() {
      let title = this.$t('labels.createLearningPaths');
      if (this.$store.getters['system/pageEditing']) {
        title = this.learningPath.name;
      }
      return title;
    },
    prevLinks() {
      var links = [
        {
          title: this.$t('labels.learningPaths'),
          route: {
            name: 'learning-paths-list',
          },
        },
      ];

      return links;
    },
  },
};
</script>
