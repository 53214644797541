<template>
  <div>
    <!-- {{ learningPath }} -->
    <v-card elevation="0">
      <v-card-text>
        <v-form ref="form" v-model="flags.isValid" @submit.prevent>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="learningPath.langs"
                outlined
                :items="$store.state.system.langs"
                :label="$tt.capitalize($t('labels.languages'))"
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="langsSelectedToggle">
                    <v-list-item-action>
                      <v-icon>
                        {{ langsSelectedIconCtl }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $tt.capitalize($t('labels.selectAll')) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>

                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    <span>{{ $tt.capitalize($t('labels.' + item)) }}</span>
                  </span>
                  <span
                    v-if="index === 1"
                    class="grey--text caption select_aux"
                  >
                    (+{{ learningPath.langs.length - 1 }}
                    {{ $t('labels.others') }})
                  </span>
                </template>

                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>{{ $tt.capitalize($t('labels.' + item)) }} </span>
                        <v-spacer></v-spacer>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <f-lang-selector :langs="learningPath.langs" />
              <v-tabs-items v-model="selectedLang">
                <v-tab-item v-for="lang in learningPath.langs" :key="lang">
                  <div class="input-spacer">
                    <v-text-field
                      v-model="learningPath.title[lang]"
                      :rules="requiredRule"
                      outlined
                      :label="
                        $tt.capitalize($t('labels.title')) +
                          $tt.uppercase(` (${lang})`)
                      "
                      :counter="maxTitleSize"
                    />
                  </div>
                </v-tab-item>
              </v-tabs-items>
              <f-lang-selector :langs="learningPath.langs" />
              <v-tabs-items v-model="selectedLang">
                <v-tab-item v-for="lang in learningPath.langs" :key="lang">
                  <div class="input-spacer">
                    <v-textarea
                      v-model="learningPath.description[lang]"
                      :rules="requiredRule"
                      outlined
                      :label="
                        $tt.capitalize($t('labels.description')) +
                          $tt.uppercase(` (${lang})`)
                      "
                    />
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
            <v-col cols="12" sm="12">
              <f-master-select
                v-model="learningPath.masters"
                :readonly="readonlyMasters"
                :isMaster="$store.state.user.isMaster"
                :countries="$store.state.country.list"
                :userMasters="$store.state.user.masters"
                @change="onChangeMaster"
              />
              <v-autocomplete
                v-model="learningPath.teams"
                :label="$tt.capitalize($t('labels.teams'))"
                :items="learningPathTeams"
                multiple
                chips
                outlined
              />
              <v-select
                v-model="learningPath.courses"
                :label="$tt.capitalize($t('labels.courses'))"
                :items="learningPathCourses"
                multiple
                chips
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <learningPaths-status
                :status="learningPath.status"
                @change="changeStatus"
              ></learningPaths-status>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <lms-btn-holder>
                <v-btn
                  color="primary"
                  large
                  elevation="0"
                  @click="save"
                  :disabled="sending"
                  :loading="sending"
                >
                  {{
                    flags.isEditing ? $t('labels.save') : $t('labels.create')
                  }}
                </v-btn>

                <v-btn large depressed @click="back">
                  {{ $t('labels.cancel') }}
                </v-btn>
              </lms-btn-holder>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import validatorRules from '../../helpers/validator_rules';
import MasterSelect from '../../components/shared/MasterSelect.vue';
import LearningPathsStatus from './learningPathsStatus.vue';
import { UserType } from '../../helpers/user_utils';

import FForm from '../../components/LearningPaths/Form.vue';

export default {
  components: {
    'f-master-select': MasterSelect,
    'learningPaths-status': LearningPathsStatus,
    FForm,
  },
  data: () => ({
    selected: [],
    learningPath: {
      langs: [],
      title: {},
      description: {},
      masters: [],
      teams: [],
      courses: [],
      status: 'published',
    },
    flags: {
      isEditing: false,
      isValid: false,
    },
    id: null,
    tab: 0,
    maxTitleSize: 110,
  }),
  computed: {
    selectedLang: {
      get() {
        return this.$store.state.system.selectedLang;
      },
      set(value) {
        this.$store.state.system.selectedLang = value;
      },
    },
    requiredRule() {
      return validatorRules.required(this.$t('rules.required'));
    },
    minLengthRule() {
      return [value => value.length > 0 || this.$t('rules.required')];
    },
    allLangs() {
      return (
        this.learningPath.langs.length === this.$store.state.system.langs.length
      );
    },
    someLangs() {
      return this.learningPath.langs.length > 0 && !this.allLangs;
    },
    langsSelectedIconCtl() {
      if (this.allLangs) return 'mdi-close-box';
      if (this.someLangs) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    sending() {
      const sending = this.$store.getters['learningPaths/getSending'];
      return sending;
    },
    readonlyMasters() {
      if (this.$route.name == 'learning-paths-edit') {
        const userMasters = this.$store.state.auth.user.masters;
        const canEditMasters = this.learningPath.masters
          .map(master => {
            return userMasters.includes(master);
          })
          .every(v => v);
        const isMaster = this.$store.state.auth.user.type == UserType.Master;
        return !canEditMasters && isMaster;
      } else {
        return false;
      }
    },
    learningPathTeams() {
      const teams = this.$store.getters['learningPaths/getTeams'] || 0;
      if (teams.length > 0) {
        return teams.map(this.formatTeams);
      }
      return [];
    },
    learningPathCourses() {
      const courses = this.$store.getters['learningPaths/getCourses'];
      if (courses.length > 0) {
        return courses.map(this.formatCourses);
      }
      return [];
    },
  },
  created() {
    this.loadCourses();
    this.$store.dispatch('country/loadList');
    this.$store.dispatch('learningPaths/clearTeams');
    this.learningPath.langs = this.$store.state.system.langs;
    if (this.$route.name == 'learning-paths-edit') {
      this.flags.isEditing = true;
      this.id = this.$route.params.id;
      this.get();
    }
  },
  methods: {
    async get() {
      await this.$store.dispatch('learningPaths/loadCourses');
      // await this.$store.dispatch('user/loadAuthUserData');
      await this.$store.dispatch('auth/getData', ['masters']);
      const result = await this.$store.dispatch('learningPaths/get', this.id);
      this.learningPath = result;
      this.learningPath.masters = (result.masters || []).map(master => {
        return master._id;
      });
      this.learningPath.teams = (result.teams || []).map(team => {
        return team._id;
      });
      this.learningPath.courses = (result.courses || []).map(course => {
        return course._id;
      });
    },
    langsSelectedToggle() {
      this.$nextTick(() => {
        if (this.allLangs) {
          this.learningPath.langs = [];
        } else {
          this.learningPath.langs = this.$store.state.system.langs;
        }
      });
    },
    async save() {
      const action = this.flags.isEditing ? 'update' : 'create';

      this.$refs.form.validate();
      if (!this.flags.isValid) return false;
      const data = { ...this.learningPath };

      let result, message, messageType;

      if (this.flags.isEditing) {
        delete data.learningPathsCourses;
        result = await this.$store.dispatch('learningPaths/update', data);
        if (result) {
          message = this.$t('alerts.general.successEdit');
          messageType = 'success';
        } else {
          message = this.$t('alerts.general.errorEdit');
          messageType = 'error';

          this.$gtm.trackEvent({
            category: 'learning-paths-admin-actions',
            event: 'click',
            action: 'learning-paths-click',
            label: `learning-paths-response-fail`,
            value: 0,
          });
        }
      } else {
        const valid = this.validateLangs(data);
        if (valid) {
          result = await this.$store.dispatch('learningPaths/create', data);
          if (result) {
            message = this.$t('alerts.general.successCreate');
            messageType = 'success';
          } else {
            message = this.$t('alerts.general.errorCreate');
            messageType = 'error';

            this.$gtm.trackEvent({
              category: 'learning-paths-admin-actions',
              event: 'click',
              action: 'learning-paths-click',
              label: `learning-paths-response-fail`,
              value: 0,
            });
          }
        }
      }

      this.$store.commit('snackbar/show', {
        content: message,
        type: messageType,
      });
      if (result) {
        this.back();

        this.$gtm.trackEvent({
          category: 'learning-paths-admin-actions',
          event: 'click',
          action: 'learning-paths-click',
          label: `${action}-learning-paths`,
          value: this.flags.isEditing
            ? `update-${this.$route.params.id}`
            : 'learning-path-create',
        });
      }
    },
    formatTeams(team) {
      return {
        value: team._id,
        text: team.name,
      };
    },
    formatCourses(course) {
      return {
        value: course._id,
        text: course.title['en'] || course.title['es'] || course.title['pt'],
      };
    },
    async loadTeamsByMasters(masters) {
      return this.$store.dispatch('learningPaths/loadTeams', {
        masters,
      });
    },
    onChangeMaster() {
      this.learningPath.teams = this.learningPathTeams.value;
    },
    async loadCourses() {
      return this.$store.dispatch('learningPaths/loadCourses');
    },
    validateLangs(data) {
      const valid = data.langs
        .map(lang => {
          return data.title[lang] && data.description[lang];
        })
        .every(v => v);
      if (!valid) {
        this.$store.commit('snackbar/show', {
          content: this.$t('alerts.general.verifyLanguages'),
          type: 'error',
        });
      }
      return valid;
    },
    changeStatus(status) {
      this.learningPath.status = status;
    },
    back() {
      this.$router.push({
        name: 'learning-paths-list',
      });
    },
  },
  watch: {
    'learningPath.masters': function(value) {
      this.$nextTick(() => {
        this.loadTeamsByMasters(value);
      });
    },
    'learningPath.courses': function(value) {
      this.$emit('input', value);
    },
    'learninPath.teams': function(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.select_aux {
  margin-left: 5px;
}
.image-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(148, 148, 148);
  width: 500px;
  height: 200px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  &__image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  &__input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
}
.user-lang {
  margin: 0 0 30px 0;
}
</style>
